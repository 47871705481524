@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

:root {
  --white: #fff;
  --light: #737070;
  --primary: #009da4;
  --secondary: #b3559f;
  --light-100: #d6d6d6;
  --light-400: #fafafa;
  --dark-graish: #212529;
}

/* UTILITY CLASSES */
._bg-primary {
  background-color: var(--primary);
}

._bg-secondary {
  background-color: var(--secondary);
}

._bg-light-100 {
  background-color: var(--light-100);
}

._text-primary {
  color: var(--primary);
}

._text-dark-graish {
  color: var(--dark-graish);
}

._text-light {
  color: var(--light);
}

._text-light-100 {
  color: var(--light-100);
}

._text-secondary {
  color: var(--secondary);
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.text-grey {
  color: var(--light) !important;
}

.bg-grey {
  background-color: var(--light) !important;
}

li {
  list-style: none;
}

.max-w-620 {
  max-width: 620px;
}

.divider {
  height: 1px;
}

.qrReader-style {
  width: 320px;
}

.scan-code {
  border-radius: 8px;
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.353);
  border: none;
  outline: none;
}

.default-btn {
  border-radius: 6px;
  border: none !important;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.353);
  letter-spacing: 0.04em;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--white);
}

/* TYPOGRAPHY STYLES */
.assets-details-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;

  color: var(--light-400);
}

/*====================== ASSETS CAUSE SECTION STYLES =====================*/

/* FOOTER STYLE */

.footer h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--dark-graish);
}
.assets-heading-bar {
  position: sticky;
  top: 8px;
}
.footer h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #e9a135;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.question-checkbox {
  appearance: none;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-radius: 50%;
}

.question-checkbox:checked {
  background-image: linear-gradient(var(--primary), var(--secondary));

  border: 2px solid var(--white);
  appearance: none;
  height: 20px;
  width: 20px;
}

.question-wrapper-el {
  background: linear-gradient(45deg, #009da48a, #9b9b9bc4);
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  color: white;
  padding: 0.5em;
}

.upload-image {
  border-radius: 6px;
  height: 130px;
  width: 108px;
  margin: auto;
}

.upload-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.remove-image {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 2px;
  cursor: pointer;
  right: 2px;
  transform: scale(0.7);
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.error {
  font-size: 14px;
  display: inline-block;
  margin-top: 6px;
  color: rgba(255, 0, 0, 0.69);
}

.phone-input .PhoneInputInput {
  height: 38px;
  border: none !important;
  border-bottom: 1px solid var(--light) !important;
}

.phone-input .PhoneInputInput:focus,
.phone-input .PhoneInputInput:focus-visible {
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid var(--light) !important;
}

.account-dropdown {
  position: absolute;
  top: 100%;
  right: 10px;
  transform: translateY(-40%) !important;
  z-index: 1;
}

.account-dropdown .dropdown-toggle {
  background: transparent !important;
  border: none;
}

.account-dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  min-width: 120px !important;
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.353);
  border: none !important;
}

button:focus {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.status-radio {
  appearance: none !important;
  border: black !important;
  height: 15px !important;
  width: 15px !important;
  border-radius: 50% !important;
  border: 2px solid var(--primary) !important;
  margin: 0 4px !important;
}
.status-radio:checked {
  background-color: var(--primary) !important;
}

/* PENDING ASSETS SCHEDULE  */
.list-box {
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.175);
  max-width: 340px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.list-box:hover {
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.485);
}
.list-box-img-wrapper {
  min-width: 100px;
  height: 120px;
  margin-right: 10px;
}
.list-box-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pending-status {
  color: rgb(254, 197, 8);
}

.inspection-assets input,
.inspection-assets select {
  box-shadow: 0px 2px 3px rgba(224, 218, 218, 0.338);
  border: none;
  padding: 10px !important;
  background-color: #56565625;
  border-radius: 3px;
  outline: none !important;
}
button:disabled {
  background-color: #3f3f3fb8;
  cursor: not-allowed;
}
.view-form-box {
  /* border: 1px solid grey; */
  padding: 18px 10px;
  border-radius: 6px;
  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.496);
}
.br-6 {
  border-radius: 6px;
}
.form-value {
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
  background-color: rgba(128, 128, 128, 0.241);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

